import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';

import Layout from '../components/layout';
import Container from '../components/container';
import PageTitle from '../components/page-title';
import ExpandableSection from '../components/expandable-section';

type ConsumerArchivalCommercialOffers = {
  allConsumerArchivalCommercialOffersYaml: {
    edges: {
      node: {
        year: string;
        items: {
          date: string;
          offers: {
            name: string;
            path: string;
          }[];
        }[];
      };
    }[];
  };
};

const query = graphql`
  query {
    allConsumerArchivalCommercialOffersYaml {
      edges {
        node {
          year
          items {
            date
            offers {
              name
              path
            }
          }
        }
      }
    }
  }
`;

const ConsumerCommerce: React.FC = () => {
  const data = useStaticQuery<ConsumerArchivalCommercialOffers>(query);
  const consumerArchivalCommercialOffers = data.allConsumerArchivalCommercialOffersYaml.edges.map(({node}) => node);

  return (
    <Layout>
      <Container className="general">
        <PageTitle title="Договори та комерційні пропозиції" />

        <ul>
          <li>
            <a
              href={'/documents/consumer-commerce/Договір про постачання електричної енергії постачальником універсальних послуг (побут) (6).doc'}
              target="_blank"
              rel="noreferrer">
              <b>Договір про постачання електричної енергії постачальником універсальних послуг</b>
            </a>
          </li>
          <li>
            <a
              href={'/documents/consumer-commerce/Додаток №2 Договір про механізм самовиробництва УП (5).doc'}
              target="_blank"
              rel="noreferrer">
              Договір про механізм самовиробництва УП
            </a>
          </li>
          <li>
            <a
              href={'/documents/consumer-commerce/Договір про купівлю-продаж електричної енергії за зеленим тарифом.docx'}
              target="_blank"
              rel="noreferrer">
              Договір про купівлю-продаж електричної енергії за зеленим тарифом приватним домогосподарством
            </a>
          </li>
          <li>
            <a className="inline-block mb-20" href="/documents/Як укласти договір (побут).pdf" target="_blank">
              Як укласти договір про постачання електричної енергії для задоволення побутових потреб
            </a>
          </li>
        </ul>

        <h2>Комерційні пропозиції:</h2>
        <ul>
          <li>
            <a
              href="/documents/contracts-and-commercial-offers/Універсальна Побут (2).pdf"
              target="_blank"
            >
              Універсальна - побут
            </a>
          </li>
          <li>
            <a href="/documents/contracts-and-commercial-offers/Універсальна Побут 1 (2).pdf" target="_blank">
                Універсальна - побут 1
              </a>
            </li>
            <li>
              <a
                href="/documents/contracts-and-commercial-offers/Універсальна Побут - механізм самовиробництва 2.pdf"
                target="_blank"
              >
                Універсальна побут - механізм самовиробництва
              </a>
            </li>
          </ul>

          <h2> Архівні договори та комерційні пропозиції</h2>

          {consumerArchivalCommercialOffers.map(({year, items}) => (
            <ExpandableSection summary={year}>
              {items.map(({date, offers}) => (
                <ExpandableSection summary={date}>
                  <ul>
                    {offers.map(({name, path}) => (
                      <li>
                        <a href={path} target="_blank" rel="noreferrer">
                          {name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </ExpandableSection>
              ))}
            </ExpandableSection>
          ))}
      </Container>
    </Layout>
);
};

export default ConsumerCommerce;
